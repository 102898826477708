<template>
  <div class="search-page">
    <loader full :visible="isLoading" />
    <b-container class="my-3">
      <h2 class="my-3 mt-4">
        {{ $t('search') }}: <span class="selected-text">{{ query }}</span>
      </h2>
      <b-row v-if="!isLoading && searchedNews.length > 0">
        <b-col
          v-for="news in searchedNews"
          :key="news.id"
          cols="12"
          class="my-3"
        >
          <card-item :item="news" has-description size="vertical-md" />
        </b-col>
      </b-row>
      <div v-else-if="!isLoading">
        {{ $t('not_found') }}
      </div>
    </b-container>
  </div>
</template>

<script>
import CardItem from '@/components/cards/CardItem'
import Loader from '@/components/Loader'

export default {
  components: {
    CardItem,
    Loader
  },
  data: () => ({
    query: '',
    isLoading: false
  }),
  methods: {
    async toSearchHandler() {
      this.isLoading = true
      const value = this.query.toLowerCase()
      await this.$store.dispatch('fetchNews', {
        [`title_${this.$i18n.locale}_contains`]: value
      })
      this.isLoading = false
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    searchedNews() {
      return this.$store.state.newsModule.news
    }
  },
  watch: {
    query: 'toSearchHandler',
    locale: {
      deep: true,
      handler: 'toSearchHandler'
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.query = this.$route.params.query
      }
    }
  }
}
</script>
